import Api from '@/services/Index';

const getAll = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/exams', config);
}

const store = (data)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/exams', data);
}

const del = (exam_id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/exams/'+exam_id);
}

export default {
    getAll,
    store,
    del,
}
